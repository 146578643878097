import Cookies from 'js-cookie';
import md5 from 'md5';
import {navigate} from 'gatsby';
import moment from 'moment';

export const VALID_PASSWORD_RE =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const CookieDomains = ['37.186.119.181', 'localhost', '.hexospark.com', '192.168.0.132'];

// TODO: REMOVE THIS!!!!!!!!
// TMP FIX FOR COOKIES
setTimeout(() => {
  // @ts-ignore
  if (
    global.window &&
    global.window.location &&
    global.window.location.hostname &&
    global.window.location.hostname.indexOf('hexospark.com') > -1
  ) {
    Cookies.remove('__session', {
      domain: undefined,
    });
  }
}, 100);

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const setRawCookie = (name: string, value: string) =>
  CookieDomains.map(domain => {
    Cookies.set(name, value),
      {
        expires: 30,
        domain: domain,
      };
  });

export const getCookies = (): any => {
  let cookiesStr: string | undefined = undefined;
  let cookies = {};
  for (let domain of CookieDomains) {
    //@ts-ignore
    cookiesStr = Cookies.get('__session', {domain: domain});
    if (cookiesStr) {
      try {
        cookies = JSON.parse(decodeURIComponent(cookiesStr));
      } catch (e) {}
    }
  }

  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean, expires?: number) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({...getCookies(), [key]: value}), {
      expires: expires ? expires : 30,
      domain: domain,
    });
  });
};

export const setImpactClickIdCookie = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('__irClickId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setImpactMediaPartnerIdCookie = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('__mediaPartnerId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactClickIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__irClickId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactMediaPartnerIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__mediaPartnerId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDocumentReferrer = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('document_referrer', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeDocumentReferrer = () => {
  CookieDomains.map(domain => {
    Cookies.remove('document_referrer', {
      expires: 30,
      domain: domain,
    });
  });
};

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeCookiePolicy = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicy = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const setUserToken = (token: string) => setCookie('user-token', token);
export const setUserHash = (hash: string) => setCookie('user-hash', hash);
export const getUserToken = (): string | undefined => getCookie('user-token');
export const getUserHash = (): string | undefined => getCookie('user-hash');
export const removeUserToken = () => setCookie('user-token', null);
export const removeUserHash = () => setCookie('user-hash', null);

export const setUserEmail = (email: string) => setCookie('user-email', email);
export const getUserEmail = (): string | undefined => getCookie('user-email');
export const removeUserEmail = () => setCookie('user-email', null);

export const setUserName = (name: string) => setCookie('user-name', name);
export const getUserName = (): string | undefined => getCookie('user-name');
export const removeUserName = () => setCookie('user-name', null);

export const setNewUser = (isNew: boolean) => setCookie('new-user', isNew);
export const getNewUser = (): string | undefined => getCookie('new-user');
export const removeNewUser = () => removeCookie('new-user');

export const setShowDemoModalonExit = (show: string) => localStorage.setItem('show-demo-modal-onexit', show);
export const getShowDemoModalonExit = (): string => localStorage.getItem('show-demo-modal-onexit') || 'true';
export const removeShowDemoModalonExit = () => localStorage.removeItem('show-demo-modal-onexit');

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setRefreshToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

// export const getGravatar = (email: string, size?: number) => {
//   size = size || 80;
//   return 'https://www.gravatar.com/avatar/' + md5(email) + '?s=' + size + '&d=monsterid';
// };

export const logOut = () => {
  removeUserToken();
  removeUserHash();
  removeUserEmail();
  removeUserName();
  if (!getUserName()) {
    navigate('/');
  }
};

export const getDateAndTime = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)');
};

export const getParsedDateAndTimeGMT = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment(parsedDate).format('MMM DD, YYYY HH:mm:ss') : '';
};

export const getPackageInfo = () => {
  return !!localStorage.getItem('checkout-data');
};

export const removePackageInfo = () => {
  localStorage.removeItem('fromLogonOrRegistrationPage');
  localStorage.removeItem('checkout-data');
};

export const isValidEmail = (mail: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );

export const isValidPhoneNumber = (val: string): boolean => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(val);

export const isValidDomain = (domain: string) =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );
export const isValidUrl = (url: string) =>
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g.test(url);

export const eventStop = (e: any) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const returnDashUrl = () => {
  const host = window.location.host;
  return host.includes('8000')
    ? 'http://localhost:3000/'
    : host.includes('3280')
    ? 'http://localhost:3281/'
    : 'https://dash.hexospark.com/';
};

export const sendUserDataToExtension = (token?: any, refToken?: string, deviceKey?: string) => {
  // The ID of the extension we want to talk to.
  //! store id => jepkghbmlhdpchijeggibjkjojkphnaj

  const editorExtensionIdArr = ['gakffndhiikobghgngoklhjadghmodjd', 'jepkghbmlhdpchijeggibjkjojkphnaj', 'lgddnedhbaodplhbhpcfglffjgbpdaph'];
  //@ts-ignore
  global.window &&
    //@ts-ignore
    global.window.chrome &&
    //@ts-ignore
    global.window.chrome.runtime &&
    editorExtensionIdArr.forEach(async (item: string) => {
      try {
        //@ts-ignore
        await global.window.chrome.runtime.sendMessage(item, {token: token || null, refToken, deviceKey});
      } catch {
        return;
      }
    });

  (() => {
    document.dispatchEvent(
      new CustomEvent('extension-hexospark-token-event', {
        detail: {token: token || null, refToken, deviceKey},
      }),
    );
  })();
};
