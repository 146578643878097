import {ME, HEXOSPARK_USER} from '../graphql/queries';
import {UserContext} from '../contexts/user-context';
import {useContext, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';

const useSetUser = (only?: 'user' | 'hexospark_user' | null, func?: any) => {
  const {setUser, setHexosparkUser} = useContext(UserContext);
  const [refetch, {data: userData}] = useLazyQuery(ME, {
    fetchPolicy: 'no-cache',
    context: {clientName: 'v2'},
  });
  const [refetchUser, {data: hexosparkUserData}] = useLazyQuery(HEXOSPARK_USER, {
    fetchPolicy: 'no-cache',
    context: {clientName: 'v2'},
  });

  useEffect(() => {
    if (only !== 'hexospark_user' && userData && userData.User && userData.User.get) {
      setUser(userData.User.get.id ? {...userData.User.get} : {});
    }
  }, [userData]);

  useEffect(() => {
    if (
      only !== 'user' &&
      hexosparkUserData &&
      hexosparkUserData.HexosparkUser &&
      hexosparkUserData.HexosparkUser.getHexosparkUser
    ) {
      setHexosparkUser(
        hexosparkUserData.HexosparkUser.getHexosparkUser.hsUser &&
          hexosparkUserData.HexosparkUser.getHexosparkUser.hsUser.id
          ? {...hexosparkUserData.HexosparkUser.getHexosparkUser}
          : {},
      );
      func && func();
      localStorage.removeItem('navState');
    }
  }, [hexosparkUserData, only]);

  const getUser = () => {
    only !== 'hexospark_user' && refetch();
    only !== 'user' && refetchUser();
  };
  return [getUser];
};

export default useSetUser;
