import React from 'react';
import {Helmet} from 'react-helmet';
import {Location} from '@reach/router';

const headerData = [
  {
    title: '',
    description: '',
    path: '/404',
  },
  {
    title: 'Email Automation & CRM Designed to Spark Productivity | Hexospark',
    description:
      'Personalize emails, automate inbound follow-ups and grow your sales by nurturing relationships in one centralized platform.',
    path: '/',
  },
  {
    title: 'Email Automation & CRM Designed to Spark Productivity | Hexospark',
    description:
      'Personalize emails, automate inbound follow-ups and grow your sales by nurturing relationships in one centralized platform.',
    path: '/lifetime-special',
  },
  {
    title: 'Terms of Service | Hexospark',
    description:
      'These Terms of Service contain an Arbitration Agreement that may impact your rights to use the courts to resolve disputes.',
    path: '/terms-of-use',
  },
  {
    title: 'Customer support contacts | Hexospark',
    description: 'Customer support contacts - content change detection and alerting solution',
    path: '/contact',
  },
  {
    title: 'Privacy Policy | Hexospark',
    description:
      'Your privacy is very important to us and we have created this privacy statement in order to demonstrate our commitment to you.',
    path: '/privacy-policy',
  },
  {
    title: 'Cookie Policy | Hexospark',
    description: 'This Cookie Policy describes how and why HEXACT use cookies',
    path: '/cookie-policy',
  },
  {
    title: 'Why Choose Hexospark | Email Outreach Tool',
    description:
      'Get Hexospark and automate your email outreach! Learn why to choose Hexospark as your CRM and Email marketing tool, and what advantages it has.',
    path: '/why-hexospark',
  },
  {
    title: 'Hexospark Pricing Plans',
    description:
      'Learn about our prices for Hexospark! Get the best plan for you and your business and boost your email outreach.',
    path: '/pricing',
  },
  {
    title: 'Limited time offer | Hexospark',
    description: '',
    path: '/promo97049',
  },
  {
    title: 'Email Outreach Platform FAQ | Hexospark',
    description:
      'Do you have any questions regarding our email outreach platform? Check our FAQ or contact us and we will provide all the necessary info',
    path: '/faq',
  },
  {
    title: 'Checkout | Hexospark',
    description: '',
    path: '/pricing/checkout',
  },
  {
    title: 'Enterprise | Hexospark',
    description: '',
    path: '/enterprise',
  },
  {
    title: 'Hexospark Login | Login To Your Account',
    description: 'Login to your dashboard on Hexospark and start creating your personalized CRM and email campaigns.',
    path: '/login',
  },
  {
    title: 'Register for Hexospark Account',
    description:
      'Register in Hexospark and get your own dashboard for sending unlimited emails and creating cold outreach campaigns.',
    path: '/registration',
  },
  {
    title: 'Hexospark Public Roadmap | Development Plans',
    description:
      "We are excited to announce our Hexospark roadmap! This is a place for you to see what's coming next and how any feedback from users like yourself can help shape it.",
    path: '/roadmap',
  },
];

export default () => {
  return (
    <Location>
      {({location}) => {
        const item = headerData.find(data => {
          if (location.pathname[location.pathname.length - 1] === '/' && location.pathname !== '/') {
            return data.path === location.pathname.substr(0, location.pathname.length - 1);
          } else if (location.pathname === '/') {
            return data.path === location.pathname;
          } else {
            return location.pathname === data.path || location.pathname === data.path + '/';
          }
        });
        return (
          item && (
            <Helmet key={item.title} title={item.title}>
              <html lang="en" />
              {!location.pathname.includes('/reset-password') &&
                item.path !== '/reset-password' &&
                item.path !== '/404' && (
                  <script type="text/javascript">
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WQQQDNH');`}
                  </script>
                )}
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "Hexospark",
                    "url": "https://hexospark.com/",
                    "sameAs": "https://hexospark.com",
                    "description": "Hexospark is an all-in-one email and CRM platform designed to spark productivity. Personalize emails, automate inbound follow-ups and grow your sales by nurturing relationships in one centralized platform."
                  }
                `}
              </script>
              {location.pathname.includes('/faq') && (
                <script type="application/ld+json">
                  {`{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [
                    {
                      "@type": "Question",
                      "name": "How many email accounts can I connect to Hexospark?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "
                        You can connect one email account per stack. For example Hexospark 3X can connect 3 email accounts to send email campaigns from.
                        "
                      }
                    }, {
                    "@type": "Question",
                    "name": "What are the benefits of stacking?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "
                      Each additional plan stacked unlocks 1 GB storage and 1 additional email inbox per code.

                      Every 5th additional plan stacked unlocks 1 additional workspace and an additional 4 team seats."
                    }
                  },{
                    "@type": "Question",
                    "name": "Does Hexospark support workspaces?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we provide an additional workspace for every 5X stack.

                You will be able to assign team members to specific workspaces, ideal for managing different projects with their own campaigns and CRM."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can I invite or manage team members?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexospark 1X provides a single account.

                      Every 5th stack unlocks 1 additional workspace and an additional 4 team seats."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can you Import/Export data to and from the CRM?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, we support CSV import and export of contacts and organizations."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can I host my data in the EU?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, during signup you will be able to select the server location before adding any data to the CRM."
                    }
                  },{
                    "@type": "Question",
                    "name": "Do I need to provide my own email account?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you need to provide your own SMTP or Gmail account to send emails from."
                    }
                  },{
                    "@type": "Question",
                    "name": "Does Hexospark require Hexomatic?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexospark works independently enabling you to import/export contacts via traditional CSV files, via our browser extension and provide CRM capabilities. Our native integration with Hexomatic also provides convenient access to data and leads collected to expedite your business growth."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can we change the associated sending email down the road?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can change the sending email at any time, but this will delete any associated campaign data."
                    }
                  },{
                    "@type": "Question",
                    "name": "How will the chrome extension work?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The extension will pull contact data from the active browser tab making it easy to add the lead you find from LinkedIn (other sites also will be considered) into the CRM / email campaign and enabling you to use the data to write personalized intros."
                    }
                  },{
                    "@type": "Question",
                    "name": "Will there be any premium add-ons of any kind or the codes we buy now will cover future releases?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The codes you buy now will cover everything listed on the sales page (Bulk email sending, image personalization, CRM, template library, meeting booking, campaign tracking).

                Most probably we will add more built-in features that will be also included in these plans. If we add any additional feature that requires the use of 3rd party services in the future (with an additional cost per usage/user) then this will be provided using premium credits."
                    }
                  },{
                    "@type": "Question",
                    "name": "Do you plan to integrate with Hexowatch?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, it is planned to use Hexowatch as one of the triggers to send an email."
                    }
                  },{
                    "@type": "Question",
                    "name": "How many emails can I send using Hexospark?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We don’t set any email sending limits, but we recommend limiting the number of emails you send per day based on your email account age, reputation, and sending history. For example for newer email accounts, it is better to start small with 20-100 emails per day and gradually ramp up to ensure your emails reach the inbox."
                    }
                  },{
                    "@type": "Question",
                    "name": "What is the refund period?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "All our paid plans are covered by our 30-day refund policy, so if Hexospark is not the right fit for your business, we provide a full refund."
                    }
                  },{
                    "@type": "Question",
                    "name": "How much storage do I get?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Each code provides 1GB of storage which can be used to upload files to contacts inside the CRM and for image personalization."
                    }
                  }]
                }`}
                </script>
              )}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="canonical" href={`https://hexospark.com${location.pathname}`} />
              <meta name="msapplication-TileColor" content="#ff4c25" />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <meta name="msapplication-config" content="/browserconfig.xml" />
              {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff4c25" /> */}
              {item.path === '/lifetime-special' && <meta name="robots" content='noindex' />}
              <meta name="description" content={item.description} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={'https://hexospark.com/Home-page-illustrations.jpg'} />
              <meta property="og:url" content={`https://hexospark.com${location.pathname}`} />
              <meta property="og:title" content={item.title} />
              <meta property="og:description" content={item.description} />
              <meta property="og:locale" content={'en_US'} />
              <meta name="twitter:image" content={'https://hexospark.com/Home-page-illustrations.jpg'} />
              <meta name="twitter:title" content={item.title} />
              <meta name="twitter:description" content={item.description} />
              <meta name="twitter:card" content={'summary_large_image'} />
              <meta name="twitter:site" content={'@hexactinc'} />
            </Helmet>
          )
        );
      }}
    </Location>
  );
};
