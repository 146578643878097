import {gql} from '@apollo/client';

export const ME = gql`
  query ME {
    User {
      get {
        id
        email
        firstName
        lastName
        verifyCode
        verified
        company
        phone
        error
        photo
        pricingPackage
        promoData {
          hexowatch_ltd
        }
      }
    }
  }
`;

export const HEXOSPARK_USER = gql`
  query HEXOSPARK_USER {
    HexosparkUser {
      getHexosparkUser {
        hsUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          pending_payment

          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }
          db_location
          uploadHash
          error_code
        }
        can_use_trial
        trial_packages
        invitationInfo {
          invited_at
          invites_count
          paid_invites_count
        }
      }
    }
  }
`;
export const LIST_CUSTOMER_CARD = gql`
  query LIST_CUSTOMER_CARD($settings: ListCustomerCardsInput) {
    Billing {
      listCustomerCards(settings: $settings) {
        cards {
          id
          createdAt
          updatedAt
          cardId
          customerId
          brand
          lastDigits
          isDefault
        }
        error_code
      }
    }
  }
`;
export const GET_ACCESS_TOKEN = gql`
  query GET_ACCESS_TOKEN($settings: GetAccessTokenInput) {
    User {
      getAccessToken(settings: $settings) {
        error_code
        token
      }
    }
  }
`;

export const HEXOFY_USER = gql`
  query HEXOFY_USER {
    HexofyUser {
      getHexofyUser {
        hfUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const USER_PLANS_QUERY = gql`
  query USER_QUERY {
    User {
      getUserPlans {
        hexomatic {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexowatch {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexometer {
          pricing_package
          pricing_package_interval
          pending_payment
          
        }
        hexospark {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        error_code
      }
    }
  }`;