import React, {FC, useEffect} from 'react';
import ws from 'isomorphic-ws';
import fetch from 'isomorphic-fetch';
import {ApolloClient, HttpLink, ApolloLink, split, InMemoryCache} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {ApolloProvider} from '@apollo/client';
import {globalHistory} from '@reach/router';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getRawCookie, getUserToken} from '../helpers';

const wsurl = process.env.GATSBY_WS_HOST || 'ws://localhost:4000/api/ws';
const URL = process.env.GATSBY_HTTP_HOST || 'http://localhost:4000/api/ql';
const URL_V2 = process.env.GATSBY_HTTP_HOST || 'http://localhost:4000/api/ql';

const AuthMiddleware = new ApolloLink((operation: any, forward: any) => {
  // add the authorization to the headers
  operation.setContext(({headers}: {headers: {}}) => ({
    headers: {
      ...headers,
      authorization: getUserToken(),
      device_key: getRawCookie('device_key') || '',
      hxroot: getRawCookie('root'),
    },
  }));

  return forward ? forward(operation) : null;
});

const wsLink = new WebSocketLink({
  uri: wsurl,
  options: {
    reconnect: true,
    connectionParams: () => ({
      token: getUserToken(),
    }),
  },
  webSocketImpl: ws,
});

const httpLink = new HttpLink({
  uri: URL,
  fetch,
  credentials: 'include',
});
const httpLinkV2 = new HttpLink({
  uri: URL_V2,
  fetch,
  credentials: 'include',
});
const link_http = split(
  operation => operation.getContext().clientName === 'v2', // Routes the query to the proper client
  httpLinkV2,
  httpLink,
);
const link = split(
  ({query}: {query: any}) => {
    let definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  link_http,
);

export const GraphQLClient: any = new ApolloClient({
  link: ApolloLink.from([AuthMiddleware, link]),

  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export const ApolloWrapper: FC = ({children}) => {
  useEffect(() => {
    globalHistory.listen(({location}) => {
      //@ts-ignore
      if (window.ga) {
        //@ts-ignore
        const tracker = window.ga.getAll()[0];
        tracker.set('page', location.pathname + location.search);
        tracker.send('pageview');
      }
    });
  }, []);

  return <ApolloProvider client={GraphQLClient}>{children}</ApolloProvider>;
};
